import { UseDisclosureReturn } from "@chakra-ui/react";
import React from "react";
import Modal from "src/components/reusable/molecules/Modal";
import Projects from "src/components/ui/Projects";

const ProjectsModal: React.FC<
  Pick<UseDisclosureReturn, "isOpen" | "onClose" | "onOpen" | "onToggle">
> = ({ ...props }) => {
  return (
    <Modal {...props} size="xl" isCentered scrollBehavior="inside">
      <Modal.Overlay />
      <Modal.Content w="90%" height="90%" maxH="unset">
        <Modal.CloseButton />
        <Modal.Body px={0} py={12} overflowY="hidden">
          <Projects />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default ProjectsModal;
