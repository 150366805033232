import React from "react";
import { Props } from "./types";

const External: React.FC<Props> = ({ href, children }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export * from "./types";
export default External;
