import { Box } from "@chakra-ui/react";
import React from "react";
import Text from "src/components/reusable/atoms/Text";
import { PROJECTS_FETCH_ERROR } from "src/constants";

const Error = () => {
  return (
    <Box
      w="full"
      h="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Text color="red.400" textAlign="center">
        {PROJECTS_FETCH_ERROR}
      </Text>
    </Box>
  );
};

export default Error;
