import React from "react";
import Text from "../Text";
import type { Props } from "./types";

const Multiline: React.FC<Props> = ({ children, maxLines = 999, ...props }) => {
  return (
    <Text
      display="-webkit-box"
      textOverflow="ellipsis"
      overflow="hidden"
      fontSize="sm"
      css={{
        lineClamp: `${maxLines}`,
        WebkitLineClamp: `${maxLines}`,
        WebkitBoxOrient: "vertical",
      }}
      {...props}
    >
      {children}
    </Text>
  );
};

export default Multiline;
