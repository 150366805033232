import { Box, CircularProgress } from "@chakra-ui/react";
import React from "react";

const Loading = () => {
  return (
    <Box
      w="full"
      h="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        color="primary.400"
        trackColor="transparent"
        isIndeterminate
        size={8}
      />
    </Box>
  );
};

export default Loading;
