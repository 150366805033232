import { Box } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { usePortfolioItems } from "src/api/hooks";
import { Paginated, PortfolioItem, SuccessResponse } from "src/api/types";
import { useScrollEnd } from "src/helpers/hooks";
import Error from "./Error";
import List from "./List";
import Loading from "./Loading";

const Projects = () => {
  //Classic data
  const { data, setSize, error, isValidating } = usePortfolioItems();

  //Load more items
  const loadMore = useCallback(() => {
    if (isValidating) return; //Still loading previous results
    setSize((size) => size + 1);
  }, [setSize, isValidating]);

  //Scroll progression
  const { ref: listRef } = useScrollEnd(loadMore);

  //End of data indicator
  const reachedEnd = useMemo(() => {
    const lastPage = (
      data?.[data.length - 1] as SuccessResponse<Paginated<PortfolioItem>>
    )?.data;
    return lastPage?.current === lastPage?.total;
  }, [data]);

  //Loading state
  if (!data && !error) return <Loading />;

  //Error state
  if (!data?.length || error) return <Error />;

  return (
    <Box h="full">
      <List
        data={data}
        ref={listRef}
        isLoading={isValidating}
        reachedEnd={reachedEnd}
      />
    </Box>
  );
};

export default Projects;
