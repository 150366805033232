import React from "react";
import {
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
} from "@chakra-ui/react";
import Overlay from "./Overlay";
import Header from "./Header";
import Body from "./Body";
import Content from "./Content";
import Footer from "./Footer";
import CloseButton from "./CloseButton";

const Modal: React.FC<ChakraModalProps> & {
  Overlay: typeof Overlay;
  Header: typeof Header;
  Body: typeof Body;
  Content: typeof Content;
  Footer: typeof Footer;
  CloseButton: typeof CloseButton;
} = ({ ...props }) => {
  return <ChakraModal {...props} />;
};

Modal.Overlay = Overlay;
Modal.Header = Header;
Modal.Content = Content;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.CloseButton = CloseButton;

export default Modal;
