import { VStack, Divider, Box, Spinner } from "@chakra-ui/react";
import { AnimatePresence, m } from "framer-motion";
import React, { forwardRef } from "react";
import { ApiResponse, Paginated, PortfolioItem } from "src/api/types";
import LazyMotion from "src/components/reusable/headless/LazyMotion";
import Project from "./Project";

export interface Props {
  data: ApiResponse<Paginated<PortfolioItem>>[];
  isLoading: boolean;
  reachedEnd: boolean;
}

const List = (
  { data, isLoading, reachedEnd }: Props,
  ref: React.ForwardedRef<HTMLElement>
) => {
  return (
    <LazyMotion>
      <AnimatePresence>
        <Box h="full">
          <VStack
            ref={ref as React.RefObject<HTMLDivElement>}
            h="full"
            overflowY="auto"
            px={6}
            py={2}
            spacing={8}
            divider={<Divider borderColor="gray.300" />}
          >
            {data.map((paginated) => {
              if (!paginated.ok) return <></>; //Failed to fetch this page
              return paginated.data.results.map((item, index) => (
                <m.div
                  key={item._id}
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.5,
                    stiffness: 1200,
                    delay: index * 0.1,
                  }}
                  style={{ width: "100%" }}
                >
                  <Project {...item} />
                </m.div>
              ));
            })}
            {!reachedEnd && isLoading && (
              <Box
                py={1}
                display="flex"
                justifyContent="center"
                key="list-spinner"
              >
                <Spinner color="primary.400" size="sm" />
              </Box>
            )}
          </VStack>
        </Box>
      </AnimatePresence>
    </LazyMotion>
  );
};

export default forwardRef(List);
