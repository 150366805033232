import React from "react";
import NextImage from "next/image";
import { chakra } from "@chakra-ui/react";
import { Props } from "./types";

const Image: React.FC<Props> = ({ alt = "", ...props }) => {
  //Wrapper for chakra-next image
  const ChakraImage = chakra<typeof NextImage, Props>(NextImage, {
    shouldForwardProp: (prop) =>
      [
        "width",
        "height",
        "src",
        "alt",
        "quality",
        "placeholder",
        "blurDataURL",
        "loader",
        "layout",
      ].includes(prop),
  });

  return <ChakraImage alt={alt} {...props} />;
};

export * from "./types";
export default Image;
