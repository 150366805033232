import { Box } from "@chakra-ui/react";
import React from "react";
import { PortfolioItem } from "src/api/types";
import External from "src/components/reusable/atoms/External";
import Heading from "src/components/reusable/atoms/Heading";
import Image from "src/components/reusable/atoms/Image";
import Multiline from "src/components/reusable/atoms/Multiline";
import { useTruncationStyles } from "src/helpers/hooks";

const Project: React.FC<PortfolioItem> = ({
  title,
  url,
  image,
  description,
}) => {
  //Truncation for title
  const truncationStyles = useTruncationStyles();

  return (
    <Box
      w="100%"
      maxW={{ base: "300px", md: "100%" }}
      gap={{ md: 8 }}
      display={{ md: "flex" }}
      marginInline="auto"
    >
      <Box
        w={{ base: "100%", md: "200px" }}
        flexShrink={0}
        shadow="0 1px 10px rgba(0, 0, 0, .1)"
        rounded="lg"
      >
        <External href={url}>
          <Image
            rounded="lg"
            width={4}
            height={3}
            layout="responsive"
            objectFit="cover"
            alt={title}
            src={image}
          />
        </External>
      </Box>
      <Box mt={3} textAlign={{ base: "center", md: "start" }}>
        <Heading
          as="h4"
          fontSize="lg"
          transition="150ms ease-out"
          maxW="100%"
          _hover={{ color: "primary.400" }}
          {...truncationStyles}
        >
          <External href={url}>{title}</External>
        </Heading>
        <Multiline color="gray.500" maxLines={3} mt={1}>
          {description}
        </Multiline>
      </Box>
    </Box>
  );
};

export default Project;
